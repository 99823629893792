import { AsyncPipe } from '@angular/common';
import { Component } from '@angular/core';
import { Router, RouterLink } from '@angular/router';
import { GMB_DAY_OF_WEEK, IDictionary, IGmbLocation, IGmbTimePeriod } from '@scb-lib/index';
import { BUILD_TYPES, WHATSAPP_URI, arraySortBy } from '@scbweb-lib/index';
import {
	IOpeningTimesDialogData,
	OpeningTimesDialogComponent,
} from 'app/_shared/overlay-module/opening-times-dialog/opening-times-dialog.component';
import { OverlayService } from 'app/_shared/overlay-module/overlay.service';
import { GmbService } from 'app/_shared/services/gmb.service';
import { CookiePolicyComponent } from 'app/crm/cmp/cookie-policy/cookie-policy.component';
import { PrivacyPolicyComponent } from 'app/crm/cmp/privacy-policy/privacy-policy.component';
import { environment } from 'environments/environment';
import memo from 'memo-decorator';
import { DateTime } from 'luxon';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AnimateOnVisibleDirective } from '../../directives/animate-onvisible.directive';
import { EventPreventDefaultDirective } from '../../directives/stopevent.directive';

interface IOpeningTimes {
	openDay: GMB_DAY_OF_WEEK;
	times: { openTime: number; text: string }[];
}
@Component({
	selector: 'app-footer',
	templateUrl: './footer.component.html',
	styleUrls: ['./footer.component.scss'],
	standalone: true,
	imports: [AnimateOnVisibleDirective, RouterLink, EventPreventDefaultDirective, AsyncPipe],
})
export class FooterComponent {
	loc$: Observable<IGmbLocation> = this.gmbSrv.getLocation().pipe(
		map((loc) => {
			const today = DateTime.now().startOf('day');
			loc.specialHours.specialHourPeriods = loc.specialHours.specialHourPeriods.filter((sPeriod) => {
				const end = DateTime.fromObject(sPeriod.endDate);
				return end > today;
			});
			return loc;
		})
	);

	GMB_DAY_OF_WEEK = GMB_DAY_OF_WEEK;
	WHATSAPP_URI = WHATSAPP_URI;
	version: string = environment.version;
	buildType: string = environment.buildType === BUILD_TYPES.prod ? '' : ' - ' + environment.buildType;

	constructor(
		private router: Router,
		private gmbSrv: GmbService,
		private ovSrv: OverlayService
	) {}

	register() {
		this.router.navigate(['crm', 'register-init']);
	}

	@memo()
	getOpeningTimes(periods: IGmbTimePeriod[]): IDictionary<IOpeningTimes> {
		const retObj: IDictionary<IOpeningTimes> = {};
		periods.forEach((p) => {
			retObj[p.openDay] = retObj[p.openDay] ?? { day: p.openDay, times: [] };
			retObj[p.openDay].times.push({
				openTime: p.openTime.hours,
				text: `${p.openTime.hours}:${p.openTime.minutes ?? '00'} - ${p.closeTime.hours}:${p.closeTime.minutes ?? '00'}`,
			});
		});
		Object.values(retObj).forEach((d) => {
			d.times = d.times.sort(arraySortBy(['openTime']));
		});
		return retObj;
	}

	showAllHours(loc: IGmbLocation) {
		const data: IOpeningTimesDialogData = {
			hoursTypes: [{ hoursTypeId: 'REGULAR_HOURS', periods: loc.regularHours.periods }, ...loc.moreHours],
			specialPeriods: [...loc.specialHours.specialHourPeriods],
		};
		this.ovSrv.open(OpeningTimesDialogComponent, { data, config: { panelClass: 'dialog-max-width' } });
	}
	showSpecialHours(loc: IGmbLocation) {
		const data: IOpeningTimesDialogData = {
			hoursTypes: [],
			specialPeriods: [...loc.specialHours.specialHourPeriods],
		};
		this.ovSrv.open(OpeningTimesDialogComponent, { data, config: { panelClass: 'dialog-max-width' } });
	}

	showPrivacy() {
		this.ovSrv.open<null, null>(PrivacyPolicyComponent, { config: { panelClass: 'dialog-max-width' } });
	}

	showCookie() {
		this.ovSrv.open<null, null>(CookiePolicyComponent, { config: { panelClass: 'dialog-max-width' } });
	}
}
