"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
function memoize(func, resolver, cache) {
  var memoized = function () {
    var _a;
    var args = arguments;
    var key = resolver.apply(this, args);
    var cache = memoized.cache;
    if (cache.has(key)) {
      return cache.get(key);
    }
    var result = func.apply(this, args);
    memoized.cache = (_a = cache.set(key, result)) !== null && _a !== void 0 ? _a : cache;
    return result;
  };
  memoized.cache = cache;
  return memoized;
}
var defaultResolver = function () {
  var args = [];
  for (var _i = 0; _i < arguments.length; _i++) {
    args[_i] = arguments[_i];
  }
  return args[0];
};
exports.default = function (config) {
  if (config === void 0) {
    config = {};
  }
  return function (_, __, descriptor) {
    var _a, _b;
    if (typeof descriptor.value !== 'function') {
      throw new Error('Memoization can be applied only to methods');
    }
    var resolver = (_a = config.resolver) !== null && _a !== void 0 ? _a : defaultResolver;
    var cache = (_b = config.cache) !== null && _b !== void 0 ? _b : new Map();
    descriptor.value = memoize(descriptor.value, resolver, cache);
    return descriptor;
  };
};
