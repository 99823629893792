import { Injectable } from '@angular/core';
import { GMB_ACCOUNT_ID, GMB_LOCATION_ID, GMB_STAR_RATING, IGmbFoodMenus, IGmbLocation, IGmbReview } from '@scb-lib/index';

import {
	collection,
	CollectionReference,
	collectionSnapshots,
	doc,
	DocumentReference,
	Firestore,
	getDoc,
	limit,
	orderBy,
	query,
	QueryConstraint,
	QueryDocumentSnapshot,
	startAfter,
	where,
} from '@angular/fire/firestore';
import { from, Observable, of, throwError } from 'rxjs';
import { expand, map, shareReplay, skipWhile, switchMap, take } from 'rxjs/operators';

@Injectable({
	providedIn: 'root',
})
export class GmbService {
	constructor(private scbWebFbFs: Firestore) {}
	private location$: Observable<IGmbLocation> | undefined;

	getLocation(locationId = GMB_LOCATION_ID) {
		if (!this.location$) {
			const url = `gmb/data/locations/${locationId}`;
			const ref = doc(this.scbWebFbFs, url) as DocumentReference<IGmbLocation>;
			this.location$ = from(getDoc(ref)).pipe(
				map((snap) => snap.data() as IGmbLocation),
				switchMap((v) => {
					if (v) {
						return of(v);
					}
					console.error(`GMB location ${locationId} not found`);
					return throwError(() => new Error(`GMB location ${locationId} not found`));
				}),
				take(1),
				shareReplay(1)
			);
		}
		return this.location$;
	}

	getFoodMenus(accountId = GMB_ACCOUNT_ID, locationId = GMB_LOCATION_ID): Observable<IGmbFoodMenus> {
		const url = `gmb/data/accounts/${accountId}/locations/${locationId}/foodMenus/data`;
		return from(getDoc(doc(this.scbWebFbFs, url))).pipe(
			map((snap) => snap.data() as IGmbFoodMenus),
			shareReplay()
		);
	}

	recentFiveStarsReviewsWithComments$: Observable<IGmbReview[]> | undefined;
	getRecentFiveStarsReviewsWithComments(accountId = GMB_ACCOUNT_ID, locationId = GMB_LOCATION_ID): Observable<IGmbReview[]> {
		const url = `gmb/data/accounts/${accountId}/locations/${locationId}`;
		const minNumber = 6;
		let startingPoint: QueryDocumentSnapshot<IGmbReview> | undefined;
		const ref = collection(this.scbWebFbFs, `${url}/reviews`) as CollectionReference<IGmbReview>;
		const qc: QueryConstraint[] = [where('starRating', '==', GMB_STAR_RATING.FIVE), orderBy('updateTime', 'desc'), limit(minNumber)];

		if (!this.recentFiveStarsReviewsWithComments$) {
			this.recentFiveStarsReviewsWithComments$ = of([]).pipe(
				// expand is used to get all the reviews, not just the first batch
				// needed as we don't know how many reviews there are with comments
				// and firestore doesn't allow to query for null values without ordering by that field
				// so we can't use where('comment', '!=', null)
				// ordering by comment is not possible because then they are retrieved in alphabetical order based on the comment
				// and we want them ordered by date (most recent first)
				expand((res) => {
					const obs =
						startingPoint === undefined
							? collectionSnapshots<IGmbReview>(query(ref, ...qc))
							: collectionSnapshots<IGmbReview>(query(ref, ...qc, startAfter(startingPoint)));
					return obs.pipe(
						map((snaps) => snaps as QueryDocumentSnapshot<IGmbReview>[]),
						map((snaps) => {
							startingPoint = snaps.slice(-1)[0];
							const newRevs = snaps.map((snap) => snap.data()).filter((rev) => rev.comment != null);
							return [...res, ...newRevs];
						})
					);
				}),
				skipWhile((res) => res.length < minNumber),
				take(1),
				shareReplay(1)
			);
		}
		return this.recentFiveStarsReviewsWithComments$;
	}
}
