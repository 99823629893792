<div class="modal show">
	<div class="modal-dialog">
		<div class="modal-content">
			<div class="modal-header">
				<div class="modal-header">
					<h1 class="modal-title">Informativa sul trattamento dei dati personali</h1>
					<button aria-label="Close" type="button" class="btn-close" (click)="close()"></button>
				</div>
			</div>

			<div class="modal-body">
				<p>
					Nel rispetto di quanto disposto dal Regolamento (UE) 2016/679 sulla Protezione dei Dati Personali, ai sensi degli artt.
					12, 13 e 14, il presente documento ha lo scopo di fornire tutte le informazioni necessarie relative al trattamento dei
					dati personali.
				</p>
				<h5>Titolare del Trattamento</h5>
				<p>
					Ai sensi dell’art. 4, comma 7, il Titolare del trattamento dei dati personali è: Foody Solutions Milano srl con sede in
					Viale Andrea Doria, 44 - 20124 Milano
				</p>
				<h5>Finalità del trattamento</h5>
				<p>
					I dati personali verranno trattati con il supporto di mezzi cartacei, informatici e telematici per le seguenti finalità:
				</p>
				<ul>
					<li>1. dar corso alle richieste di contatto per la presentazione dei prodotti/servizi offerti;</li>
					<li>2. consentire l’iscrizione ad eventuali newsletters di Foody Solutions Milano srl;</li>
					<li>
						3. previo consenso, inviare comunicazioni in merito ad eventi ed altre attività di promozione di Foody Solutions
						Milano srl tramite modalità di contatto tradizionali e automatizzate;
					</li>
					<li>
						4. previo consenso, consentire l’eventuale pubblicazione sui social media e sulle pagine istituzionali di Foody
						Solutions Milano srl di riprese video e/o fotografiche effettuate nei locali a marchio Say CHEESE Bistrot.
					</li>
				</ul>
				<h5>Conferimento dei dati</h5>
				<p>
					Il conferimento dei dati è necessario per le finalità di cui ai precedenti punti 1 e 2. In assenza del conferimento dei
					dati, non sarà possibile dar corso alla richiesta dell’interessato. Il conferimento dei dati per finalità di marketing
					(punto 3) è facoltativo, il rifiuto non comporta alcuna conseguenza, salvo l’impossibilità di ricevere informazioni e
					materiale promozionale.
				</p>
				<h5>Modalità del Trattamento</h5>
				<p>
					Per trattamento di dati personali si intende la loro raccolta, registrazione, organizzazione, conservazione,
					elaborazione, modificazione, selezione, estrazione, raffronto, utilizzo, interconnessione, blocco, comunicazione,
					diffusione, cancellazione e distruzione, ovvero la combinazione di due o più di tali operazioni, anche attraverso
					strumenti automatizzati atti a memorizzare, gestire e trasmettere i dati stessi, utilizzando strumenti idonei a
					garantire la sicurezza e la riservatezza. Per quanto attiene la sicurezza, la banca dati è accessibile solo da parte del
					personale autorizzato dal Titolare, così come le relative operazioni sopra descritte e il trattamento dei dati avverrà
					attraverso mezzi elettronici o automatizzati ed attraverso mezzi non automatizzati (archivi cartacei), forniti entrambi
					di adeguate misure di sicurezza, così come previste dal Regolamento (UE) 2016/679 sulla Protezione dei Dati Personali,
					per prevenire la perdita dei dati, gli usi illeciti o non corretti e gli accessi non autorizzati. I dati raccolti non
					verranno utilizzati in contesti o per usi e/o fini diversi da quelli sopra indicati o che possano, in qualsiasi modo,
					compromettere i diritti e le libertà dei soggetti interessati.
				</p>
				<h5>Destinatari dei Dati</h5>
				<p>
					Fatte salve eventuali comunicazioni per adempiere ad obblighi di legge, Foody Solutions Milano srl comunicherà i dati
					personali dei soggetti che li avranno conferiti solo a fornitori e soggetti terzi selezionati per la realizzazione di
					attività correlate e ausiliarie alle finalità sopra indicate, ed opportunamente autorizzati al trattamento, quali
					partners commerciali e operativi, che operano in collaborazione con il Titolare, per l’esecuzione delle finalità
					anzidette.
				</p>
				<h5>Diritti degli interessati</h5>
				<p>
					Ai sensi del Regolamento Europeo, gli interessati hanno il diritto di chiedere al Titolare del trattamento l'accesso ai
					dati personali (art. 15), la rettifica (art. 16), la cancellazione degli stessi o l’oblio (art. 17), la limitazione del
					trattamento dei dati personali che lo riguardano (art. 18), il diritto di opporsi al loro trattamento (art. 21), oltre
					al diritto di non essere sottoposto a una decisione basata unicamente sul trattamento automatizzato, compresa la
					profilazione, che produca effetti giuridici che lo riguardano o che incida allo stesso modo significativamente sulla sua
					persona (art. 22). Qualora il trattamento dei dati personali si basi sul consenso espresso fornito dal soggetto
					interessato, ai sensi dell’art. 7 paragrafo 3 del Regolamento, è riconosciuta la possibilità di recedere in ogni momento
					dall’iscrizione al servizio di newsletter ovvero revocare il consenso fornito per l’invio di comunicazioni e
					aggiornamenti per finalità promozionali e commerciali. Le richieste potranno essere esercitate nei confronti del
					Titolare del trattamento, scrivendo all’indirizzo e-mail: foody.solutions&#64;gmail.com L’interessato ha, altresì, il
					diritto di proporre reclamo all'autorità di controllo preposta (art. 77 del Regolamento) qualora ritenga che il
					trattamento effettuato da parte del Titolare non sia conforme alle disposizioni di legge sulla protezione dei dati
					personali.
				</p>

				<p class="text-muted text-right">Versione policy {{ PRIVACY_POLICY_VERSION }}</p>
			</div>

			<div class="modal-footer">
				<div class="w-100 d-flex justify-content-center align-items-center">
					<button aria-label="close" class="d-block btn btn-primary" (click)="close()">CHIUDI</button>
				</div>
			</div>
		</div>
	</div>
</div>
