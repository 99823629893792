import { Overlay, OverlayConfig } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { Injectable, Injector, TemplateRef, Type, inject } from '@angular/core';
import { OverlayComponent } from './overlay/overlay.component';
import { MyOverlayRef } from './OverlayRef.class';

@Injectable({
	providedIn: 'root',
})
export class OverlayService {
	cdkOverlaySrv = inject(Overlay);
	injector = inject(Injector);

	// R = Response Data Type, T = Data passed to Modal Type
	open<R = unknown, D = unknown>(
		content: string | TemplateRef<unknown> | Type<unknown>,
		options?: { data?: D; config?: OverlayConfig }
	): MyOverlayRef<R | null, D | undefined> {
		const configs = new OverlayConfig({
			hasBackdrop: true,
			...(options?.config ?? {}),
		});
		const overlayRef = this.cdkOverlaySrv.create(configs);

		const myOverlayRef = new MyOverlayRef<R | null, D | undefined>(overlayRef, content, options?.data);

		const injector = this.createInjector(myOverlayRef, this.injector);

		overlayRef.attach(new ComponentPortal(OverlayComponent, null, injector));
		// console.log('overlayRef', overlayRef);

		return myOverlayRef;
	}

	private createInjector<R = unknown, D = unknown>(ref: MyOverlayRef<R | null, D | undefined>, inj: Injector) {
		const providers = [{ provide: MyOverlayRef, useValue: ref }];
		const injObj = Injector.create({ providers, parent: inj });
		return injObj;
	}
}
