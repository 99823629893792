
@switch (contentType) {
  @case ('template') {
    <ng-container *ngTemplateOutlet="$any(content); context: {close: context?.close}"></ng-container>
  }
  @case ('component') {
    <ng-container *ngComponentOutlet="$any(content)"></ng-container>
  }
  @case ('string') {
    <div class="modal show">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-body">
            <div [innerHTML]="content"></div>
          </div>
          <div class="modal-footer">
            <button (click)="close()" class="btn btn-primary" aria-label="close">CHIUDI</button>
          </div>
        </div>
      </div>
    </div>
  }
}
