export enum BUILD_TYPES {
    prod = 'PROD',
    demo = 'DEMO',
    test = 'TEST',
    dev = 'DEV',
}

export interface IEnvironment {
    production: boolean;
    buildType: BUILD_TYPES;
    version: string;
    appName: string;
    domain: string;
    functionsDomain: string;
    firabase_DB_BASE_REF: string;
}

// tslint:disable-next-line:no-empty-interface
export interface IAppEnv extends IEnvironment {

}

export interface IFirebaseConfig {
    apiKey: string;
    authDomain: string;
    databaseURL: string;
    projectId: string;
    storageBucket: string;
    messagingSenderId: string;
    appId: string;
    measurementId: string;
}

export interface IFunctionsUri {
    authApi: string;
    counterApi: string;
}
