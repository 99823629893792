<footer class="app-footer app-bg-dark app-section">
	<div class="container">
		<div class="row mb-3">
			<div class="col-md-6 col-lg-3">
				<div class="app-footer-widget mb-4">
					<h2 class="app-heading">Say CHEESE Bistrot</h2>
					<p>Cheese based fine foods, wines, beers and cocktails</p>
				</div>
			</div>

			@if (loc$ | async; as loc) {
				<div class="col-md-6 col-lg-3 no-print">
					<div class="app-footer-widget mb-4">
						<h2 class="app-heading">
							Orari
							<span style="font-size: 0.875rem; font-weight: normal">
								- <a (click)="showAllHours(loc)" href="javascript:">vedi tutti gli orari</a>
							</span>
						</h2>
						@if (loc.specialHours.specialHourPeriods.length) {
							<p style="color: red">
								Attenzione: alcuni orari subiranno delle variazioni nei prossimi giorni.
								<a (click)="showSpecialHours(loc)" href="javascript:">Vedi</a>
							</p>
						}
						@if (getOpeningTimes(loc.regularHours.periods); as ot) {
							<ul class="list-unstyled open-hours">
								<li class="d-flex justify-content-between align-items-baseline">
									<div>Lunedì</div>
									@if (ot[GMB_DAY_OF_WEEK.MONDAY]; as otDay) {
										<div>
											@for (time of otDay.times; track time) {
												<div>{{ time.text }}</div>
											}
										</div>
									} @else {
										<div>Chiuso</div>
									}
								</li>
								<li class="d-flex justify-content-between align-items-baseline">
									<div>Martedì</div>
									@if (ot[GMB_DAY_OF_WEEK.TUESDAY]; as otDay) {
										<div>
											@for (time of otDay.times; track time) {
												<div>{{ time.text }}</div>
											}
										</div>
									} @else {
										<div>Chiuso</div>
									}
								</li>
								<li class="d-flex justify-content-between align-items-baseline">
									<div>Mercoledì</div>
									@if (ot[GMB_DAY_OF_WEEK.WEDNESDAY]; as otDay) {
										<div>
											@for (time of otDay.times; track time) {
												<div>{{ time.text }}</div>
											}
										</div>
									} @else {
										<div>Chiuso</div>
									}
								</li>
								<li class="d-flex justify-content-between align-items-baseline">
									<div>Giovedì</div>
									@if (ot[GMB_DAY_OF_WEEK.THURSDAY]; as otDay) {
										<div>
											@for (time of otDay.times; track time) {
												<div>{{ time.text }}</div>
											}
										</div>
									} @else {
										<div>Chiuso</div>
									}
								</li>
								<li class="d-flex justify-content-between align-items-baseline">
									<div>Venerdì</div>
									@if (ot[GMB_DAY_OF_WEEK.FRIDAY]; as otDay) {
										<div>
											@for (time of otDay.times; track time) {
												<div>{{ time.text }}</div>
											}
										</div>
									} @else {
										<div>Chiuso</div>
									}
								</li>
								<li class="d-flex justify-content-between align-items-baseline">
									<div>Sabato</div>
									@if (ot[GMB_DAY_OF_WEEK.SATURDAY]; as otDay) {
										<div>
											@for (time of otDay.times; track time) {
												<div>{{ time.text }}</div>
											}
										</div>
									} @else {
										<div>Chiuso</div>
									}
								</li>
								<li class="d-flex justify-content-between align-items-baseline">
									<div>Domenica</div>
									@if (ot[GMB_DAY_OF_WEEK.SUNDAY]; as otDay) {
										<div>
											@for (time of otDay.times; track time) {
												<div>{{ time.text }}</div>
											}
										</div>
									} @else {
										<div>Chiuso</div>
									}
								</li>
							</ul>
						}
					</div>
				</div>
			}

			<div class="col-md-6 col-lg-3">
				<div class="app-footer-widget mb-4">
					<h2 class="app-heading">Contatti</h2>

					<ul class="list-unstyled">
						<li class="d-flex align-items-start">
							<div class="icon"><i class="fas fa-map-marker-alt"></i></div>
							<a
								class="d-block condensed-line-height"
								href="https://maps.google.com/maps?cid=6958316458932508478"
								target="_blank">
								Via Luisa Battistotti Sassi 12<br />20133, Milano
							</a>
						</li>

						<li class="d-flex align-items-center">
							<div class="icon"><i class="fas fa-directions"></i></div>
							<a
								class="d-block"
								href=" https://www.google.com/maps/dir//Say+CHEESE+Bistrot/data=!4m8!4m7!1m0!1m5!1m1!1s0x4786c74dca4e6663:0x6090e7f3e16d173e!2m2!1d9.228059799999999!2d45.463465799999994"
								target="blank">
								Indicazioni stradali
							</a>
						</li>

						<li class="d-flex align-items-center">
							<div class="icon"><i class="fas fa-phone-alt"></i></div>
							<a class="d-block" href="tel:+390209942265">+39 02 09942265</a>
						</li>

						<li class="d-flex align-items-center">
							<div class="icon"><i class="fab fa-whatsapp"></i></div>
							<a class="d-block" [href]="WHATSAPP_URI" target="blank">Whatsapp</a>
						</li>

						<div class="d-flex align-items-center">
							<div class="icon"><i class="fas fa-envelope"></i></div>
							<a class="d-block" href="mailto:info@saycheesebistrot.com">info&#64;saycheesebistrot.com</a>
						</div>
					</ul>
				</div>
			</div>

			<div class="col-md-6 col-lg-3 no-print">
				<div class="app-footer-widget mb-4">
					<h2 class="app-heading">Aggiornamenti</h2>

					<p>Seguici sui social</p>

					<ul class="app-footer-social list-unstyled mt-3">
						<li appAnimateOnVisible class="">
							<a href="https://www.facebook.com/saycheesebistrot" target="blank"><i class="fab fa-facebook-f"></i></a>
						</li>
						<li appAnimateOnVisible class="">
							<a href="https://www.instagram.com/saycheesebistrot" target="blank"><i class="fab fa-instagram"></i></a>
						</li>
						<li appAnimateOnVisible class="">
							<a
								href="https://www.tripadvisor.it/Restaurant_Review-g187849-d20029401-Reviews-Say_Cheese_Bistrot-Milan_Lombardy.html"
								target="blank">
								<img alt="tripadvisor_icon" width="40px" height="40px" src="assets/icons/tripadvisor_icon.png" />
							</a>
						</li>
					</ul>

					<p>Iscriviti alla newsletter</p>

					<input type="button" class="d-block w-100 btn btn-primary" (click)="register()" value="Registrati" />
					<div class="my-3">Sei già registrato? <a [routerLink]="'/auth/login-email'">Accedi</a></div>
				</div>
			</div>
		</div>
		<div class="row">
			<div class="col-md-12 text-center">
				<p style="color: var(--muted)">
					Copyright &copy; 2021. All rights reserved. <br />
					Versione: {{ version }}{{ buildType }}
				</p>
				<p>
					Foody Solutions Milano s.r.l. P.IVA e CF 10611730960. Sede Legale: Viale A. Doria 44, 20124, Milano, Italia. REA
					MI-2545057 Capitale Sociale € 10.000 i.v.
				</p>
			</div>
		</div>
		<div class="row no-print">
			<div class="col-md-12 text-center">
				<p style="color: var(--muted)">
					Questo esercizio è stato avviato con il contributo POR FESR 2014-2020 di Regione Lombardia, Azione 3.a.1.1
				</p>
			</div>
		</div>
		<div class="row no-print">
			<div class="col-md-6 text-center">
				<a appEventPreventDefault (click)="showPrivacy()" href>Informativa sulla privacy</a>
			</div>
			<div class="col-md-6 text-center">
				<a appEventPreventDefault (click)="showCookie()" href>Informativa sui cookie</a>
			</div>
		</div>
	</div>
</footer>
