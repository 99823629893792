<div tabindex="-1" class="modal fade show d-block">
	<div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
		<div class="modal-content">
			<div class="modal-header">
				<h1 class="modal-title">Orari di apertura</h1>
				<button aria-label="Close" type="button" class="btn-close" (click)="close()" data-bs-dismiss="modal"></button>
			</div>

			<div class="modal-body">
				@if (specialPeriods.length) {
					<article>
						<h4 style="color: red">Aperture o chiusure speciali</h4>
						<ul class="list-unstyled w-100">
							@for (specialPeriod of specialPeriods; track specialPeriod) {
								<li class="d-flex justify-content-start align-items-baseline gap-1">
									<div class="me-auto">{{ specialPeriod.date | date: 'mediumDate' }}</div>
									<div>{{ specialPeriod.isClosed ? ' Chiuso' : ' Aperto' }}</div>
									@if (!specialPeriod.isClosed) {
										<div>
											@for (time of specialPeriod.times; track time) {
												<div>{{ time.text }}</div>
											}
										</div>
									}
								</li>
							}
						</ul>
					</article>
				}

				@if (hoursTypes.length) {
					@for (hoursType of hoursTypes; track hoursType) {
						<article>
							<h4>
								@switch (hoursType.hoursTypeId) {
									@case ('REGULAR_HOURS') {
										<span>Locale</span>
									}
									@case ('KITCHEN') {
										<span>Cucina</span>
									}
									@case ('HAPPY_HOUR') {
										<span>Aperitivo Happy Hour</span>
									}
									@case ('DELIVERY') {
										<span>Delivery</span>
									}
									@case ('BRUNCH') {
										<span>Brunch</span>
									}
									@case ('TAKEOUT') {
										<span>Asporto</span>
									}
									@default {
										<span>{{ hoursType.hoursTypeId }}</span>
									}
								}
							</h4>
							@if (hoursType.ot; as ot) {
								<ul class="list-unstyled w-100" class="list-unstyled">
									<li class="d-flex justify-content-between align-items-baseline">
										<div>Lunedì</div>
										@if (ot[GMB_DAY_OF_WEEK.MONDAY]; as otDay) {
											<div>
												@for (time of otDay.times; track time) {
													<div>{{ time.text }}</div>
												}
											</div>
										} @else {
											<div>Chiuso</div>
										}
									</li>
									<li class="d-flex justify-content-between align-items-baseline">
										<div>Martedì</div>
										@if (ot[GMB_DAY_OF_WEEK.TUESDAY]; as otDay) {
											<div>
												@for (time of otDay.times; track time) {
													<div>{{ time.text }}</div>
												}
											</div>
										} @else {
											<div>Chiuso</div>
										}
									</li>
									<li class="d-flex justify-content-between align-items-baseline">
										<div>Mercoledì</div>
										@if (ot[GMB_DAY_OF_WEEK.WEDNESDAY]; as otDay) {
											<div>
												@for (time of otDay.times; track time) {
													<div>{{ time.text }}</div>
												}
											</div>
										} @else {
											<div>Chiuso</div>
										}
									</li>
									<li class="d-flex justify-content-between align-items-baseline">
										<div>Giovedì</div>
										@if (ot[GMB_DAY_OF_WEEK.THURSDAY]; as otDay) {
											<div>
												@for (time of otDay.times; track time) {
													<div>{{ time.text }}</div>
												}
											</div>
										} @else {
											<div>Chiuso</div>
										}
									</li>
									<li class="d-flex justify-content-between align-items-baseline">
										<div>Venerdì</div>
										@if (ot[GMB_DAY_OF_WEEK.FRIDAY]; as otDay) {
											<div>
												@for (time of otDay.times; track time) {
													<div>{{ time.text }}</div>
												}
											</div>
										} @else {
											<div>Chiuso</div>
										}
									</li>
									<li class="d-flex justify-content-between align-items-baseline">
										<div>Sabato</div>
										@if (ot[GMB_DAY_OF_WEEK.SATURDAY]; as otDay) {
											<div>
												@for (time of otDay.times; track time) {
													<div>{{ time.text }}</div>
												}
											</div>
										} @else {
											<div>Chiuso</div>
										}
									</li>
									<li class="d-flex justify-content-between align-items-baseline">
										<div>Domenica</div>
										@if (ot[GMB_DAY_OF_WEEK.SUNDAY]; as otDay) {
											<div>
												@for (time of otDay.times; track time) {
													<div>{{ time.text }}</div>
												}
											</div>
										} @else {
											<div>Chiuso</div>
										}
									</li>
								</ul>
							}
						</article>
					}
				}

				<!-- <div class="modal-footer">

        <button (click)="close()" class="d-block me-1 me-lg-3 btn btn-outline-primary" aria-label="cancel">
          Chiudi
        </button>

      </div> -->
			</div>
		</div>
	</div>
</div>
