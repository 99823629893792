import { ChangeDetectionStrategy, ChangeDetectorRef, Component, model, signal } from '@angular/core';
import { LANG, LANG_ARRAY } from '@scb-lib/index';
import { UiService } from '@shared/services/ui.service';

@Component({
	selector: 'app-language-switcher',
	standalone: true,
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [],
	template: `
		<div class="chooseLang" [class.open]="languageChooserOpen()">
			<div>
				@for (lang of langs(); track lang) {
					<button
						aria-label="Italiano"
						class="button-unstyled lang "
						[attr.data-lang]="lang"
						[class.chosenLang]="uiSrv.curLangSgn() === lang"
						(click)="languageChooserOpen() ? changeLang(lang) : openLangChooser()"></button>
				}
			</div>
		</div>
	`,
	styles: `
		:host {
			display: contents;
		}
		.chooseLang {
			--flag-ratio: 0.5833333333; // 35/60
			--width: 30px;
			--expanded-width: 45px;
			display: flex;
			height: 100%;
			justify-content: center;
			align-items: center;
			width: var(--width, 30px);
		}
		.chooseLang div {
			width: var(--width, 30px);
			height: calc(var(--width, 30px) * var(--flag-ratio));
			position: relative;
			flex: 0 0 var(--width);
		}
		.lang {
			cursor: pointer;
			display: block;
			position: absolute;
			background-size: contain;
			background-repeat: no-repeat;
			opacity: 0;
			left: 0;
			top: 0;
			transition: all 0.76s;
			height: calc(var(--width, 30px) * var(--flag-ratio));
			width: var(--width);
		}
		.lang[data-lang='it'] {
			background-image: url(/assets/images/flag-it.jpg);
		}
		.lang[data-lang='en'] {
			background-image: url(/assets/images/flag-en.jpg);
		}
		.open .lang {
			position: absolute;
			z-index: 3;
			width: var(--expanded-width) !important;
			height: calc(var(--expanded-width, 30px) * var(--flag-ratio)) !important;
			left: calc((var(--expanded-width) - var(--width)) / 2 * -1);
			opacity: 1;
		}
		.open .lang:nth-of-type(1) {
			top: 100%;
		}
		.open .lang:nth-of-type(2) {
			top: calc(100% + calc(var(--expanded-width, 30px) * var(--flag-ratio)) + 8px);
		}

		.chooseLang div .lang.chosenLang {
			opacity: 1;
			z-index: 1;
		}
	`,
})
export class LanguageSwitcherComponent {
	LANG = LANG;
	languageChooserOpen = model(false);
	langs = signal<LANG[]>([...LANG_ARRAY]);

	constructor(
		public uiSrv: UiService,
		private cd: ChangeDetectorRef
	) {}

	changeLang(lang: LANG) {
		if (this.uiSrv.curLangSgn() === lang) return;

		this.uiSrv.setCurrentLang(lang);
		this.languageChooserOpen.set(false);
	}

	openLangChooser() {
		this.langs.set([this.uiSrv.curLangSgn(), ...LANG_ARRAY.filter((l) => l !== this.uiSrv.curLangSgn())]);
		setTimeout(() => {
			//needed to wait for the new lang buttons to be rendered after changed array, otherwise animation won't work
			this.languageChooserOpen.set(true);
		}, 0);
		setTimeout(() => {
			this.languageChooserOpen.set(false);
			this.cd?.detectChanges();
		}, 2000);
	}
}
