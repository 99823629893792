import { DOCUMENT } from '@angular/common';
import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, Inject, ViewChild } from '@angular/core';
import { MyOverlayRef } from 'app/_shared/overlay-module/OverlayRef.class';

@Component({
	selector: 'app-cookie-policy',
	templateUrl: './cookie-policy.component.html',
	styleUrls: ['./cookie-policy.component.scss'],
	standalone: true,
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CookiePolicyComponent implements AfterViewInit {
	@ViewChild('policyContainer', { read: ElementRef }) policyContainer!: ElementRef;

	constructor(
		private ref: MyOverlayRef<undefined, null>,
		@Inject(DOCUMENT) private document: Document,
		private elementRef: ElementRef
	) {}

	ngAfterViewInit(): void {
		const s = this.document.createElement('script');
		s.type = 'text/javascript';
		s.src = 'https://consent.cookiebot.com/d3b2915e-864b-4b56-9f3c-c8fe11dd4498/cd.js';
		s.id = 'CookieDeclaration';
		s.async = true;

		this.policyContainer.nativeElement.appendChild(s);
	}

	close() {
		this.ref.close();
	}
}
