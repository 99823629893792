import { Directive, HostListener } from '@angular/core';

@Directive({
	selector: '[appEventStopPropagation]',
	standalone: true,
})
export class EventStopPropagationDirective {
	@HostListener('click', ['$event'])
	public onClick(event: Event): void {
		event.stopPropagation();
	}
}

@Directive({
	selector: '[appEventPreventDefault]',
	standalone: true,
})
export class EventPreventDefaultDirective {
	@HostListener('click', ['$event'])
	public onClick(event: Event): void {
		event.preventDefault();
	}
}
