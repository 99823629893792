import { isPlatformServer } from '@angular/common';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable, PLATFORM_ID, computed, inject } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import {
	Auth,
	User,
	isSignInWithEmailLink,
	onAuthStateChanged,
	sendSignInLinkToEmail,
	signInWithEmailLink,
	signOut,
} from '@angular/fire/auth';
import { Firestore, doc, getDoc, setDoc, updateDoc } from '@angular/fire/firestore';
import { Router } from '@angular/router';
import { IContact, IContactAdd } from '@scb-lib/index';
import { CRM_API_ADDRESS, CRM_API_ENDPOINTS } from '@scbweb-lib/index';
import { environment } from 'environments/environment';
import { isEqual } from 'lodash-es';
import { BehaviorSubject, Observable, firstValueFrom, from, of, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

const CRM_API_URL = `${environment.functionsDomain}/${CRM_API_ADDRESS}`;

export interface IUser {
	fbUser: User;
	contact: IContact | null;
}

@Injectable({
	providedIn: 'root',
})
export class AuthService {
	scbWebFbFs = inject(Firestore);
	scbWebFbAuth = inject(Auth);
	router = inject(Router);
	platformId = inject(PLATFORM_ID);
	http = inject(HttpClient);

	// user: IUser | null | undefined = undefined;
	private userSbj$: BehaviorSubject<IUser | undefined> = new BehaviorSubject<IUser | undefined>(undefined);
	user$ = this.userSbj$.asObservable();
	contact$ = this.user$.pipe(map((u) => u?.contact));
	userSgn = toSignal(this.user$);
	isAuthedSgn = computed(() => !!this.userSgn());

	constructor() {
		this.scbWebFbAuth.languageCode = 'it';
		this.listenAuthStateChange();
	}

	listenAuthStateChange() {
		onAuthStateChanged(this.scbWebFbAuth, async (fbUser) => {
			if (fbUser) {
				const contact = (await getDoc(doc(this.scbWebFbFs, `contacts/${fbUser.uid}`))).data() as IContact;

				if (!contact) {
					this.router.navigate(['/user/profile'], { queryParams: { firstAccess: true }, queryParamsHandling: 'merge' });
				}
				this.userSbj$.next({ fbUser, contact });
			} else {
				this.userSbj$.next(undefined);
			}
		});
	}

	async updateContactInUser() {
		if (this.userSbj$.value?.fbUser) {
			const contact = (await getDoc(doc(this.scbWebFbFs, `contacts/${this.userSbj$.value?.fbUser.uid}`))).data() as IContact;
			if (!isEqual(this.userSbj$.value.contact, contact)) {
				const user = { ...this.userSbj$.value, contact };
				this.userSbj$.next(user);
			}
		}
	}

	sendLoginLinkToEmail(email: string, params?: HttpParams): Promise<void> {
		if (isPlatformServer(this.platformId)) {
			return Promise.reject();
		}
		const DOMAIN = window.location.host.startsWith('localhost') ? 'http://' + window.location.host : 'https://' + window.location.host;
		const url = params ? `${DOMAIN}/auth/login-finish-with-link?${params.toString()}` : `${DOMAIN}/auth/login-finish-with-link`;
		// console.log({ url });
		const actionCodeSettings = {
			// URL you want to redirect back to. The domain (www.example.com) for this
			// URL must be in the authorized domains list in the Firebase Console.
			url,
			// iOS: {
			//   bundleId: 'com.example.ios'
			// },
			// android: {
			//   packageName: 'com.example.android',
			//   installApp: true,
			//   minimumVersion: '12'
			// },
			// This must be true.
			handleCodeInApp: true,
			// dynamicLinkDomain: 'example.page.link'
		};
		return sendSignInLinkToEmail(this.scbWebFbAuth, email, actionCodeSettings).then(() => {
			// The link was successfully sent. Inform the user.
			// Save the email locally so you don't need to ask the user for it again
			// if they open the link on the same device.
			window.localStorage.setItem('emailForSignIn', email);
		});
	}

	confirmIsLoginLinkAndEmail(link: string): boolean {
		return isSignInWithEmailLink(this.scbWebFbAuth, link);
	}

	loginWithEmailLink(email: string, link: string) {
		return signInWithEmailLink(this.scbWebFbAuth, email, link).then(() => {
			// Clear email from storage.
			window.localStorage.removeItem('emailForSignIn');
			// You can access the new user via result.user
			// Additional user info profile not available via:
			// result.additionalUserInfo.profile == null
			// You can check if the user is new or existing:
			// result.additionalUserInfo.isNewUser
		});
	}

	logOut(): Promise<void> {
		return signOut(this.scbWebFbAuth);
	}

	async checkContactExistByEmail(email: string): Promise<boolean> {
		console.log('🚀 ~ file: auth.service.ts:124 ~ checkContactExistByEmail ~ checkContactExistByEmail:', email);

		const url = new URL(`${CRM_API_URL}/${CRM_API_ENDPOINTS.checkContactExistByEmail}`);

		// url.search = new URLSearchParams({ email }).toString();
		// const options = {
		// 	method: 'GET',
		// 	url,
		// 	headers: {
		// 		'Content-Type': 'application/json',
		// 	},
		// };

		// try {
		// 	const response = await fetch(url, options);
		// 	if (response.status !== 200) throw new Error(await response.text());
		// 	return await response.json();
		// } catch (error: unknown) {
		// 	console.error({ error });
		// 	if (isError(error)) {
		// 		throw new Error(error.message);
		// 	} else {
		// 		throw new Error(error as string);
		// 	}
		// }

		return firstValueFrom(
			this.http.get<boolean>(url.toString(), { params: { email } }).pipe(
				catchError((err) => {
					console.error({ err });
					throw new Error(err.message);
				})
			)
		);
	}

	async checkContactExistByMobile(mobile: string): Promise<boolean> {
		const url = new URL(`${CRM_API_URL}/${CRM_API_ENDPOINTS.checkContactExistByMobile}`);

		// url.search = new URLSearchParams({ mobile }).toString();
		// const options = {
		// 	method: 'GET',
		// 	url,
		// 	headers: {
		// 		'Content-Type': 'application/json',
		// 	},
		// };
		// try {
		// 	const response = await fetch(url, options);
		// 	if (response.status !== 200) throw new Error(await response.text());
		// 	return await response.json();
		// } catch (error: unknown) {
		// 	console.error({ error });
		// 	if (isError(error)) {
		// 		throw new Error(error.message);
		// 	} else {
		// 		throw new Error(error as string);
		// 	}
		// }

		return firstValueFrom(
			this.http.get<boolean>(url.toString(), { params: { mobile } }).pipe(
				catchError((err) => {
					console.error({ err });
					throw new Error(err.message);
				})
			)
		);
	}

	async addNewContact(client: IContactAdd): Promise<IContact> {
		const url = new URL(`${CRM_API_URL}/${CRM_API_ENDPOINTS.addNewContact}`);
		// const options = {
		// 	method: 'POST',
		// 	url,
		// 	headers: {
		// 		'Content-Type': 'application/json',
		// 	},
		// 	body: JSON.stringify(client),
		// };
		// try {
		// 	const response = await fetch(url, options);
		// 	if (response.status !== 200) throw new Error(await response.text());
		// 	return await response.json();
		// } catch (error: unknown) {
		// 	console.error({ error });
		// 	if (isError(error)) {
		// 		throw new Error(error.message);
		// 	} else {
		// 		throw new Error(error as string);
		// 	}
		// }

		return firstValueFrom(
			this.http.post<IContact>(url.toString(), client).pipe(
				catchError((err) => {
					console.error({ err });
					throw new Error(err.message);
				})
			)
		);
	}

	async getContactByEmail(email: string): Promise<IContact | null> {
		const url = new URL(`${CRM_API_URL}/${CRM_API_ENDPOINTS.getContactByEmail}`);
		// url.search = new URLSearchParams({ email }).toString();
		// const options = {
		// 	method: 'GET',
		// 	url,
		// 	headers: {
		// 		'Content-Type': 'application/json',
		// 	},
		// };
		// try {
		// 	const response = await fetch(url, options);
		// 	if (response.status !== 200) throw new Error(await response.text());
		// 	return await response.json();
		// } catch (error: unknown) {
		// 	console.error({ error });
		// 	if (isHttpError(error)) {
		// 		if (error.status === '404') {
		// 			return null;
		// 		} else {
		// 			throw new Error(error.message);
		// 		}
		// 	} else if (isError(error)) {
		// 		throw new Error(error.message);
		// 	} else {
		// 		throw new Error(error as string);
		// 	}
		// }

		// const url = `${CRM_API_URL}/${CRM_API_ENDPOINTS.getContactByEmail}`;
		return firstValueFrom(
			this.http.get<IContact>(url.toString(), { params: { email } }).pipe(
				catchError((err) => {
					console.error({ err });
					if (err.status === '404') {
						return of(null);
					} else {
						return throwError(() => new Error(err.message));
					}
				})
			)
		);
	}

	getContactById(id: string): Observable<IContact> {
		return from(getDoc(doc(this.scbWebFbFs, `contacts/${id}`))).pipe(map((snap) => snap.data() as IContact));
	}

	updateContactById(id: string, contact: Partial<IContact>) {
		return from(updateDoc(doc(this.scbWebFbFs, `contacts/${id}`), contact));
	}

	setContactById(id: string, contact: IContact) {
		return from(setDoc(doc(this.scbWebFbFs, `contacts/${id}`), contact));
	}
}
