<div class="modal show">
	<div class="modal-dialog">
		<div class="modal-content">
			<div class="modal-header">
				<h1 class="modal-title">Informativa sui Cookie</h1>
				<button (click)="close()" type="button" class="btn-close" aria-label="Close"></button>
			</div>

			<div class="modal-body">
				<div #policyContainer></div>
			</div>

			<div class="modal-footer">
				<div class="w-100 d-flex justify-content-center align-items-center">
					<button (click)="close()" class="d-block btn btn-primary" aria-label="close">CHIUDI</button>
				</div>
			</div>
		</div>
	</div>
</div>
