import { Component } from '@angular/core';
import { GMB_DAY_OF_WEEK, IDictionary, IGmbSpecialHoursPeriod, IGmbTimePeriod } from '@scb-lib/index';
import { arraySortBy } from '@scbweb-lib/index';
import { cloneDeep } from 'lodash-es';
import { DateTime } from 'luxon';
import { MyOverlayRef } from '../OverlayRef.class';
import { DatePipe } from '@angular/common';

export interface IOpeningTimesDialogData {
	hoursTypes?: {
		hoursTypeId: string;
		periods: IGmbTimePeriod[];
	}[];
	specialPeriods?: IGmbSpecialHoursPeriod[];
}

interface ISpecialPeriod {
	date: string;
	isClosed: boolean;
	times: { openTime: number; text: string }[];
}

interface IOpeningTimes {
	openDay: GMB_DAY_OF_WEEK;
	times: { openTime: number; text: string }[];
}

@Component({
	selector: 'app-opening-times-dialog',
	templateUrl: './opening-times-dialog.component.html',
	styleUrls: ['./opening-times-dialog.component.scss'],
	standalone: true,
	imports: [DatePipe],
})
export class OpeningTimesDialogComponent {
	GMB_DAY_OF_WEEK = GMB_DAY_OF_WEEK;

	today = DateTime.now().startOf('day');

	hoursTypes: { hoursTypeId: string; ot: IDictionary<IOpeningTimes> }[];
	specialPeriods: ISpecialPeriod[];

	constructor(public ref: MyOverlayRef<void, IOpeningTimesDialogData>) {
		const data = cloneDeep(this.ref.data);
		this.hoursTypes =
			data?.hoursTypes?.map((ht) => ({
				hoursTypeId: ht.hoursTypeId,
				ot: this.getOpeningTimes(ht.periods),
			})) ?? [];
		this.specialPeriods = this.getSpecialPeriods(data?.specialPeriods ?? []);
	}

	getOpeningTimes(periods: IGmbTimePeriod[]): IDictionary<IOpeningTimes> {
		const retObj: IDictionary<IOpeningTimes> = {};
		periods.forEach((p) => {
			retObj[p.openDay] = retObj[p.openDay] ?? { day: p.openDay, times: [] };
			retObj[p.openDay].times.push({
				openTime: p.openTime.hours,
				text: `${p.openTime.hours}:${p.openTime.minutes ?? '00'} - ${p.closeTime.hours}:${p.closeTime.minutes ?? '00'}`,
			});
		});
		Object.values(retObj).forEach((d) => {
			d.times = d.times.sort(arraySortBy(['openTime']));
		});
		return retObj;
	}

	getSpecialPeriods(periods: IGmbSpecialHoursPeriod[]): ISpecialPeriod[] {
		const retObj: IDictionary<ISpecialPeriod> = {};
		console.log(periods);
		periods.forEach((p) => {
			if (DateTime.fromObject(p.endDate).startOf('day') < this.today) {
				return;
			}
			const d = DateTime.fromObject(p.startDate);
			if (!d.isValid) {
				return;
			}
			const dString = d.toISODate();
			retObj[dString] = retObj[dString] ?? { date: dString, isClosed: p.closed, times: [] };
			if (!retObj[dString].isClosed) {
				if (p.openTime) {
					retObj[dString].times.push({
						openTime: p.openTime.hours,
						text: `${p.openTime.hours}:${p.openTime.minutes ?? '00'} - ${p.closeTime.hours ?? '00'}:${
							p.closeTime.minutes ?? '00'
						}`,
					});
				}
			}
			retObj[dString].times = retObj[dString].times.sort(arraySortBy(['openTime']));
		});
		return Object.values(retObj).sort(arraySortBy(['date']));
	}

	close() {
		this.ref.close();
	}
}
