import { AfterViewInit, ChangeDetectionStrategy, Component, DestroyRef, ElementRef, ViewEncapsulation, inject, input } from '@angular/core';
import { takeUntilDestroyed, toObservable } from '@angular/core/rxjs-interop';
import { UiService } from '@shared/services/ui.service';
import { EMPTY, debounceTime, switchMap, tap } from 'rxjs';

@Component({
	selector: 'app-scb-logo',
	standalone: true,
	encapsulation: ViewEncapsulation.None,
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [],
	template: `
		<svg id="logo_scb" aria-labelledby="title desc" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 3395.69 1291.2">
			<title id="title">Say CHEESE Bistrot logo</title>
			<desc id="desc">Logo of Say CHEESE Bistrot</desc>
			<defs>
				<style>
					#logo-knife-shape,
					#logo-cheese-shape,
					#logo-arc-shape {
						fill: var(--_logoorange, #d57e00);
					}

					#logo-glass-shape {
						fill: var(--_logored, #6a1a31);
					}

					#logo-cheeseshape,
					#logo-cheese-text {
						stroke-width: var(--_stroke-small);
					}

					#logo-cheese-text,
					#logo-say-text,
					#logo-bistrot-text {
						fill: var(--_logobrown, #443736);
						stroke: var(--_logobrown, #443736);
					}

					#logo-arc-shape,
					#logo-say-text,
					#logo-bistrot-text {
						stroke-width: var(--_stroke-big);
					}
				</style>
			</defs>

			<path
				id="logo-arc-shape"
				d="m1719.79,1291.2c-181.76,0-352.82-59.32-481.67-167.02-130.01-108.67-201.61-253.52-201.61-407.84h45.06c0,292.13,286.3,529.8,638.21,529.8s638.21-237.67,638.21-529.8h45.07c0,154.33-71.6,299.17-201.61,407.84-128.84,107.71-299.9,167.02-481.67,167.02Z" />
			<g id="logo-say-text">
				<path
					d="m31.88,560.33c-21.26-16.32-31.88-38.25-31.88-65.8v-17.85c0-3.4,1.7-5.1,5.1-5.1h8.67c3.4,0,5.1,1.71,5.1,5.1v16.32c0,22.11,9.09,39.96,27.29,53.56,18.19,13.6,43.1,20.4,74.72,20.4,29.92,0,52.87-6.29,68.86-18.87,15.98-12.58,23.97-29.75,23.97-51.52,0-13.6-3.4-25.33-10.2-35.19-6.81-9.86-17.34-19.13-31.62-27.8-14.28-8.67-34.52-18.62-60.7-29.84-26.52-11.22-46.5-20.57-59.93-28.05-13.44-7.48-24.06-16.83-31.88-28.05-7.83-11.22-11.73-25.5-11.73-42.85,0-27.54,9.26-48.62,27.8-63.25,18.53-14.62,44.63-21.93,78.29-21.93,36.72,0,65.29,8.25,85.69,24.74,20.4,16.5,30.6,38.69,30.6,66.56v12.24c0,3.4-1.7,5.1-5.1,5.1h-9.69c-3.4,0-5.1-1.7-5.1-5.1v-10.71c0-22.78-8.5-40.97-25.5-54.58-17.01-13.6-40.98-20.4-71.92-20.4-27.54,0-48.71,5.7-63.5,17.09-14.79,11.4-22.19,27.8-22.19,49.22,0,14.28,3.32,25.85,9.95,34.68,6.63,8.85,16.15,16.58,28.56,23.21,12.41,6.63,31.54,15.22,57.38,25.76,25.5,10.54,46.07,20.66,61.72,30.35,15.64,9.69,27.71,20.75,36.21,33.15,8.5,12.42,12.75,27.12,12.75,44.12,0,27.21-9.95,48.97-29.84,65.29-19.89,16.32-48.03,24.48-84.41,24.48s-66.23-8.16-87.47-24.48Z" />
				<path
					d="m439.81,343.3c14.79,15.13,22.19,35.96,22.19,62.48v169.85c0,3.4-1.71,5.1-5.1,5.1h-9.69c-3.4,0-5.1-1.7-5.1-5.1v-25.5c0-.68-.25-1.1-.76-1.27-.51-.17-1.11.09-1.79.77-8.5,11.56-20.15,20.32-34.94,26.27-14.79,5.95-30.86,8.93-48.2,8.93-23.46,0-43.27-5.69-59.42-17.09-16.16-11.39-24.23-29.33-24.23-53.81s9.26-44.8,27.8-58.91c18.53-14.11,44.12-21.17,76.76-21.17h62.74c1.36,0,2.04-.68,2.04-2.04v-26.52c0-20.4-5.61-36.64-16.83-48.71-11.22-12.07-28.74-18.11-52.54-18.11-18.36,0-33.5,3.83-45.4,11.48-11.91,7.65-19.21,17.94-21.93,30.86-1.02,3.4-3.06,4.93-6.12,4.59l-10.71-1.53c-1.36-.34-2.55-.93-3.57-1.79-1.02-.84-1.36-1.79-1.02-2.81,3.4-17.68,13.17-31.88,29.33-42.59,16.15-10.71,36.29-16.07,60.44-16.07,29.24,0,51.26,7.57,66.05,22.7Zm-22.7,207.34c16.66-10.88,24.99-26.18,24.99-45.91v-52.03c0-1.35-.68-2.04-2.04-2.04h-61.72c-25.5,0-46.16,5.53-61.97,16.58-15.81,11.05-23.72,26.27-23.72,45.65,0,17.68,6.12,31.11,18.36,40.29,12.24,9.18,28.05,13.77,47.44,13.77,22.44,0,41.99-5.44,58.66-16.32Z" />
				<path
					d="m507.29,677.65v-7.65c0-3.4,1.7-5.1,5.1-5.1h2.04c12.92-.34,22.87-2.64,29.84-6.89,6.96-4.26,13.09-11.82,18.36-22.7,5.27-10.89,11.99-28.56,20.15-53.05.68-1.36.68-2.38,0-3.06l-88.24-248.91-.51-2.04c0-2.37,1.53-3.57,4.59-3.57h9.69c3.06,0,5.1,1.36,6.12,4.08l77.02,223.91c.33.69.84,1.02,1.53,1.02s1.19-.33,1.53-1.02l75.49-223.91c1.02-2.72,3.06-4.08,6.12-4.08h9.69c3.74,0,5.1,1.87,4.08,5.61l-97.93,279c-7.48,21.42-14.46,37.23-20.91,47.44-6.46,10.2-13.86,17.09-22.19,20.66-8.34,3.57-19.81,5.36-34.43,5.36h-3.57c-2.38,0-3.57-1.71-3.57-5.1Z" />
			</g>
			<g id="logo-bistrot-text" class="bistrot-text-bold">
				<path
					d="m1284.54,790.08c0,.14.21.28.62.42,7.49,2.91,13.32,7.46,17.48,13.63,4.16,6.17,6.24,13.63,6.24,22.37,0,11.93-3.74,21.4-11.23,28.4-7.49,7.01-17.34,10.51-29.54,10.51h-48.48c-1.39,0-2.08-.69-2.08-2.08v-141.48c0-1.38.69-2.08,2.08-2.08h47.02c12.34,0,22.19,3.36,29.54,10.09,7.35,6.73,11.03,15.78,11.03,27.15,0,7.77-1.91,14.43-5.72,19.97-3.82,5.55-9.26,9.78-16.33,12.69-.42.14-.62.28-.62.42Zm-58.88-62.21v57.84c0,.56.28.83.83.83h40.15c9.85,0,17.75-2.67,23.72-8.01,5.96-5.34,8.95-12.38,8.95-21.12s-2.98-16.5-8.95-22.05c-5.97-5.55-13.87-8.32-23.72-8.32h-40.15c-.56,0-.83.28-.83.83Zm66.06,121.19c6.03-5.89,9.05-13.56,9.05-22.99s-3.09-17.69-9.26-23.51c-6.17-5.83-14.39-8.74-24.65-8.74h-40.36c-.56,0-.83.28-.83.83v62.42c0,.56.28.83.83.83h41.61c9.71,0,17.58-2.95,23.61-8.84Z" />
				<path
					d="m1390.23,863.31v-141.48c0-1.38.69-2.08,2.08-2.08h3.95c1.38,0,2.08.7,2.08,2.08v141.48c0,1.39-.7,2.08-2.08,2.08h-3.95c-1.39,0-2.08-.69-2.08-2.08Z" />
				<path
					d="m1488.12,857.07c-8.67-6.66-13-15.6-13-26.84v-7.28c0-1.38.69-2.08,2.08-2.08h3.54c1.38,0,2.08.7,2.08,2.08v6.66c0,9.02,3.71,16.3,11.13,21.85,7.42,5.55,17.58,8.32,30.48,8.32,12.2,0,21.57-2.56,28.09-7.7,6.52-5.13,9.78-12.14,9.78-21.01,0-5.55-1.39-10.33-4.16-14.36-2.78-4.02-7.07-7.8-12.9-11.34-5.83-3.54-14.08-7.59-24.76-12.17-10.82-4.58-18.97-8.39-24.45-11.44-5.48-3.05-9.82-6.87-13-11.44-3.19-4.58-4.79-10.4-4.79-17.48,0-11.23,3.78-19.83,11.34-25.8,7.56-5.96,18.21-8.95,31.94-8.95,14.98,0,26.63,3.36,34.95,10.09,8.32,6.73,12.48,15.78,12.48,27.15v4.99c0,1.39-.7,2.08-2.08,2.08h-3.95c-1.39,0-2.08-.69-2.08-2.08v-4.37c0-9.29-3.47-16.71-10.4-22.26-6.94-5.55-16.72-8.32-29.34-8.32-11.24,0-19.87,2.32-25.9,6.97-6.03,4.65-9.05,11.34-9.05,20.08,0,5.83,1.35,10.54,4.06,14.15,2.7,3.61,6.59,6.76,11.65,9.47,5.06,2.7,12.86,6.21,23.41,10.51,10.4,4.3,18.79,8.43,25.17,12.38,6.38,3.95,11.3,8.46,14.77,13.52,3.47,5.06,5.2,11.06,5.2,18,0,11.1-4.06,19.97-12.17,26.63-8.11,6.66-19.59,9.99-34.43,9.99s-27.01-3.33-35.68-9.99Z" />
				<path
					d="m1725.62,721.83v3.12c0,1.39-.7,2.08-2.08,2.08h-41.82c-.56,0-.83.28-.83.83v135.44c0,1.39-.7,2.08-2.08,2.08h-3.95c-1.39,0-2.08-.69-2.08-2.08v-135.44c0-.55-.28-.83-.83-.83h-40.15c-1.39,0-2.08-.69-2.08-2.08v-3.12c0-1.38.69-2.08,2.08-2.08h91.75c1.38,0,2.08.7,2.08,2.08Z" />
				<path
					d="m1885.4,863.94l-32.66-67.62c-.14-.42-.42-.62-.83-.62h-42.44c-.56,0-.83.28-.83.83v66.79c0,1.39-.7,2.08-2.08,2.08h-3.95c-1.39,0-2.08-.69-2.08-2.08v-141.48c0-1.38.69-2.08,2.08-2.08h50.97c11.37,0,20.56,3.54,27.57,10.61,7,7.07,10.51,16.3,10.51,27.67,0,9.99-2.74,18.28-8.22,24.86-5.48,6.59-12.87,10.65-22.16,12.17-.7.28-.9.62-.62,1.04l32.46,66.79c.14.28.21.62.21,1.04,0,.97-.56,1.46-1.66,1.46h-3.75c-1.25,0-2.08-.48-2.5-1.46Zm-76.77-136.07v60.13c0,.56.28.83.83.83h43.9c9.01,0,16.3-2.84,21.85-8.53,5.55-5.69,8.32-13.04,8.32-22.05s-2.78-16.82-8.32-22.57c-5.55-5.75-12.83-8.63-21.85-8.63h-43.9c-.56,0-.83.28-.83.83Z" />
				<path
					d="m1986.41,861.23c-7.15-3.88-12.66-9.33-16.54-16.33-3.88-7-5.83-15.08-5.83-24.24v-55.97c0-9.15,1.94-17.27,5.83-24.34,3.88-7.07,9.39-12.55,16.54-16.44,7.14-3.88,15.36-5.83,24.65-5.83s17.72,1.94,24.86,5.83c7.14,3.89,12.69,9.36,16.64,16.44,3.95,7.07,5.93,15.19,5.93,24.34v55.97c0,9.15-1.98,17.24-5.93,24.24-3.95,7.01-9.5,12.45-16.64,16.33-7.15,3.88-15.43,5.83-24.86,5.83s-17.51-1.94-24.65-5.83Zm53.16-12.07c7.21-7.07,10.82-16.44,10.82-28.09v-56.59c0-11.79-3.61-21.25-10.82-28.4-7.21-7.14-16.72-10.71-28.5-10.71s-21.05,3.57-28.19,10.71c-7.15,7.15-10.72,16.61-10.72,28.4v56.59c0,11.65,3.57,21.01,10.72,28.09,7.14,7.07,16.54,10.61,28.19,10.61s21.29-3.54,28.5-10.61Z" />
				<path
					d="m2222.03,721.83v3.12c0,1.39-.69,2.08-2.08,2.08h-41.82c-.56,0-.83.28-.83.83v135.44c0,1.39-.7,2.08-2.08,2.08h-3.95c-1.39,0-2.08-.69-2.08-2.08v-135.44c0-.55-.28-.83-.83-.83h-40.15c-1.39,0-2.08-.69-2.08-2.08v-3.12c0-1.38.69-2.08,2.08-2.08h91.75c1.38,0,2.08.7,2.08,2.08Z" />
			</g>
			<g id="logo-bistrot-text" class="bistrot-text-std">
				<path
					d="M1368.34,774.97c0,0.11,0.17,0.23,0.5,0.34c6.05,2.35,10.75,6.02,14.11,11c3.36,4.98,5.04,11,5.04,18.06
			c0,9.63-3.02,17.28-9.07,22.93c-6.05,5.66-14,8.48-23.86,8.48h-39.14c-1.12,0-1.68-0.56-1.68-1.68V719.86
			c0-1.12,0.56-1.68,1.68-1.68h37.97c9.97,0,17.92,2.72,23.86,8.15c5.94,5.43,8.9,12.74,8.9,21.92c0,6.27-1.54,11.65-4.62,16.13
			c-3.08,4.48-7.48,7.9-13.19,10.25C1368.51,774.75,1368.34,774.86,1368.34,774.97z M1320.79,724.74v46.7
			c0,0.45,0.22,0.67,0.67,0.67h32.42c7.95,0,14.33-2.16,19.15-6.47c4.81-4.31,7.22-10,7.22-17.05c0-7.39-2.41-13.33-7.22-17.81
			c-4.82-4.48-11.2-6.72-19.15-6.72h-32.42C1321.02,724.06,1320.79,724.29,1320.79,724.74z M1374.13,822.6
			c4.87-4.76,7.31-10.95,7.31-18.56c0-7.95-2.49-14.28-7.48-18.98c-4.99-4.7-11.62-7.06-19.91-7.06h-32.59
			c-0.45,0-0.67,0.23-0.67,0.67v50.4c0,0.45,0.22,0.67,0.67,0.67h33.6C1362.91,829.74,1369.26,827.36,1374.13,822.6z" />
				<path
					d="M1453.68,834.1V719.86c0-1.12,0.56-1.68,1.68-1.68h3.19c1.12,0,1.68,0.56,1.68,1.68V834.1
			c0,1.12-0.56,1.68-1.68,1.68h-3.19C1454.24,835.78,1453.68,835.23,1453.68,834.1z" />
				<path
					d="M1532.72,829.06c-7-5.38-10.5-12.6-10.5-21.67v-5.88c0-1.12,0.56-1.68,1.68-1.68h2.86
			c1.12,0,1.68,0.56,1.68,1.68v5.38c0,7.28,2.99,13.16,8.99,17.64c5.99,4.48,14.2,6.72,24.61,6.72c9.85,0,17.41-2.07,22.68-6.22
			c5.26-4.14,7.9-9.8,7.9-16.97c0-4.48-1.12-8.34-3.36-11.59c-2.24-3.25-5.71-6.3-10.42-9.16c-4.7-2.86-11.37-6.13-19.99-9.83
			c-8.74-3.7-15.32-6.78-19.74-9.24c-4.43-2.46-7.93-5.54-10.5-9.24c-2.58-3.7-3.86-8.4-3.86-14.11c0-9.07,3.05-16.02,9.16-20.83
			c6.1-4.81,14.7-7.22,25.79-7.22c12.1,0,21.5,2.72,28.22,8.15c6.72,5.43,10.08,12.74,10.08,21.92v4.03c0,1.12-0.56,1.68-1.68,1.68
			h-3.19c-1.12,0-1.68-0.56-1.68-1.68v-3.53c0-7.5-2.8-13.5-8.4-17.98c-5.6-4.48-13.5-6.72-23.69-6.72
			c-9.07,0-16.04,1.88-20.92,5.63c-4.87,3.75-7.31,9.16-7.31,16.21c0,4.7,1.09,8.51,3.28,11.42c2.18,2.91,5.32,5.46,9.41,7.64
			c4.09,2.18,10.39,5.01,18.9,8.48c8.4,3.47,15.18,6.8,20.33,10c5.15,3.19,9.13,6.83,11.93,10.92c2.8,4.09,4.2,8.93,4.2,14.53
			c0,8.96-3.28,16.13-9.83,21.5c-6.55,5.38-15.82,8.06-27.8,8.06C1549.33,837.13,1539.72,834.44,1532.72,829.06z" />
				<path
					d="M1724.49,719.86v2.52c0,1.12-0.56,1.68-1.68,1.68h-33.77c-0.45,0-0.67,0.23-0.67,0.67V834.1
			c0,1.12-0.56,1.68-1.68,1.68h-3.19c-1.12,0-1.68-0.56-1.68-1.68V724.74c0-0.45-0.23-0.67-0.67-0.67h-32.42
			c-1.12,0-1.68-0.56-1.68-1.68v-2.52c0-1.12,0.56-1.68,1.68-1.68h74.09C1723.93,718.18,1724.49,718.75,1724.49,719.86z" />
				<path
					d="M1853.52,834.61l-26.38-54.6c-0.11-0.34-0.34-0.5-0.67-0.5h-34.27c-0.45,0-0.67,0.23-0.67,0.67v53.93
			c0,1.12-0.56,1.68-1.68,1.68h-3.19c-1.12,0-1.68-0.56-1.68-1.68V719.86c0-1.12,0.56-1.68,1.68-1.68h41.16
			c9.18,0,16.6,2.86,22.26,8.57c5.65,5.71,8.48,13.16,8.48,22.34c0,8.06-2.21,14.76-6.64,20.08c-4.43,5.32-10.39,8.6-17.89,9.83
			c-0.56,0.23-0.73,0.5-0.5,0.84l26.21,53.93c0.11,0.23,0.17,0.5,0.17,0.84c0,0.78-0.45,1.18-1.34,1.18h-3.02
			C1854.53,835.78,1853.85,835.39,1853.52,834.61z M1791.53,724.74v48.55c0,0.45,0.22,0.67,0.67,0.67h35.45
			c7.28,0,13.16-2.29,17.64-6.89c4.48-4.59,6.72-10.53,6.72-17.81c0-7.5-2.24-13.58-6.72-18.23c-4.48-4.65-10.36-6.97-17.64-6.97
			h-35.45C1791.75,724.06,1791.53,724.29,1791.53,724.74z" />
				<path
					d="M1935.08,832.42c-5.77-3.13-10.22-7.53-13.36-13.19c-3.14-5.65-4.7-12.18-4.7-19.57v-45.19
			c0-7.39,1.57-13.94,4.7-19.66c3.13-5.71,7.59-10.14,13.36-13.27c5.77-3.13,12.4-4.7,19.91-4.7c7.62,0,14.31,1.57,20.08,4.7
			c5.77,3.14,10.25,7.56,13.44,13.27c3.19,5.71,4.79,12.26,4.79,19.66v45.19c0,7.39-1.6,13.92-4.79,19.57
			c-3.19,5.66-7.67,10.05-13.44,13.19c-5.77,3.14-12.46,4.7-20.08,4.7C1947.48,837.13,1940.85,835.56,1935.08,832.42z M1978,822.68
			c5.82-5.71,8.74-13.27,8.74-22.68v-45.7c0-9.52-2.91-17.16-8.74-22.93c-5.82-5.77-13.5-8.65-23.02-8.65
			c-9.41,0-17,2.88-22.76,8.65c-5.77,5.77-8.65,13.41-8.65,22.93V800c0,9.41,2.88,16.97,8.65,22.68c5.77,5.71,13.36,8.57,22.76,8.57
			C1964.51,831.25,1972.18,828.39,1978,822.68z" />
				<path
					d="M2125.34,719.86v2.52c0,1.12-0.56,1.68-1.68,1.68h-33.77c-0.45,0-0.67,0.23-0.67,0.67V834.1
			c0,1.12-0.56,1.68-1.68,1.68h-3.19c-1.12,0-1.68-0.56-1.68-1.68V724.74c0-0.45-0.23-0.67-0.67-0.67h-32.42
			c-1.12,0-1.68-0.56-1.68-1.68v-2.52c0-1.12,0.56-1.68,1.68-1.68h74.09C2124.78,718.18,2125.34,718.75,2125.34,719.86z" />
			</g>
			<g id="logo-cheese-text">
				<path
					d="m1088.32,336.11h-33.67l-8.96-49.89c-7.75-7.59-17.28-13.81-28.58-18.65-11.3-4.84-24.79-7.27-40.45-7.27-36.66,0-64.83,13.28-84.53,39.84-19.7,26.56-29.55,60.27-29.55,101.12v5.57c0,40.85,9.65,74.64,28.94,101.36,19.29,26.73,46.06,40.08,80.29,40.08,15.99,0,30.52-2.5,43.6-7.51,13.08-5,23.17-11.14,30.28-18.41l8.96-49.89h33.67v62.73c-10.17,13.08-25.43,24.75-45.78,35-20.34,10.26-43.92,15.38-70.72,15.38-45.86,0-83.48-16.95-112.87-50.86-29.39-33.91-44.08-76.54-44.08-127.88v-5.09c0-51.67,14.45-94.42,43.35-128.25,28.9-33.83,66.76-50.74,113.59-50.74,26.8,0,50.38,5.21,70.72,15.62,20.34,10.41,35.6,22.08,45.78,35v62.73Z" />
				<path
					d="m1114.98,258.61v-30.76h126.67v30.76l-39.48,6.78v121.83h171v-121.83l-39.48-6.78v-30.76h126.67v30.76l-39.48,6.78v277.81l39.48,6.78v30.52h-126.67v-30.52l39.48-6.78v-118.44h-171v118.44l39.48,6.78v30.52h-126.67v-30.52l39.48-6.78v-277.81l-39.48-6.78Z" />
				<path
					d="m1476.13,580.49v-30.52l39.48-6.78v-277.81l-39.48-6.78v-30.76h270.78v84.77h-40.93l-3.88-47.23h-138.78v114.08h134.91v37.54h-134.91v126.19h141.69l3.88-47.23h40.69v84.53h-273.45Z" />
				<path
					d="m1772.6,580.49v-30.52l39.48-6.78v-277.81l-39.48-6.78v-30.76h270.78v84.77h-40.93l-3.88-47.23h-138.78v114.08h134.91v37.54h-134.91v126.19h141.69l3.87-47.23h40.69v84.53h-273.45Z" />
				<path
					d="m2324.36,331.99h-36.09l-8.96-50.38c-7.27-6.13-16.95-11.22-29.06-15.26-12.11-4.03-26.16-6.05-42.14-6.05-25.35,0-44.81,5.37-58.37,16.11-13.56,10.74-20.34,24.75-20.34,42.02,0,15.34,6.9,28.14,20.71,38.39,13.81,10.26,36.13,19.26,66.97,27.01,37.14,9.2,65.35,22.77,84.65,40.69,19.29,17.92,28.94,40.13,28.94,66.61,0,28.42-11.59,51.27-34.76,68.54-23.17,17.28-53.49,25.92-90.95,25.92-27.13,0-51.23-4.44-72.3-13.32-21.07-8.88-38.23-20.5-51.47-34.88v-65.64h35.85l9.2,50.62c9.2,7.59,20.18,13.81,32.94,18.65,12.75,4.84,28.01,7.27,45.78,7.27,24.06,0,43.07-5.09,57.04-15.26,13.96-10.17,20.95-23.98,20.95-41.42,0-16.14-6.05-29.67-18.16-40.57-12.11-10.9-33.51-20.06-64.18-27.49-37.3-8.88-66.45-21.96-87.44-39.24-20.99-17.28-31.49-38.99-31.49-65.15,0-27.61,11.7-50.58,35.12-68.91,23.41-18.32,53.85-27.49,91.31-27.49,24.71,0,47.19,4.24,67.45,12.71,20.26,8.48,36.53,18.77,48.8,30.88v65.64Z" />
				<path
					d="m2351.26,580.49v-30.52l39.48-6.78v-277.81l-39.48-6.78v-30.76h270.78v84.77h-40.93l-3.87-47.23h-138.78v114.08h134.91v37.54h-134.91v126.19h141.69l3.87-47.23h40.69v84.53h-273.45Z" />
			</g>
			<path
				id="logo-glass-shape"
				d="m3358.99,79.73c-5.72-28.07-16.4-57.31-23.88-76.83l-1.13-2.9h-155.27l-1.13,2.9c-7.53,19.52-18.17,48.76-23.93,76.83-9.09,44.43-13.47,83.61,25.29,112.63,8.07,6.05,17.02,11.67,25.67,17.06,15.52,9.72,30.16,18.86,34.59,27.52,2.37,8.04,3.75,33.07,4.18,60.29,8.74,1.35,17.37,2,25.84,4.32.34-29.35,1.73-56.05,4.26-64.61,4.42-8.66,19.07-17.8,34.55-27.52,8.67-5.4,17.62-11.01,25.73-17.06,38.74-29.05,34.37-68.2,25.23-112.63Zm-69.84,44.03l-.51-.07c-1.73-.25-42.69-6.14-60.91-12.22-13.38-4.45-39.75,5.58-48.76,9.83l-.63.29-28.73,4.1-1.27-8.92,27.41-3.92c4.99-2.28,36.49-16.04,54.83-9.93,16.4,5.47,54.06,11.07,58.85,11.77l71.64-5.97.75,8.99-72.66,6.05Z" />
			<path
				id="logo-cheese-shape"
				d="m3395.69,369.29c-13.11-99.51-481.24-62.21-481.24-62.21l183.81,55.07-238.85-13.49c-1.96,2.18-4.22,4.28-6.79,6.27l-4.39,9.65c-.43,2.45-1.5,7.22-3.55,10.81,2.93,3.31,7.51,9.13,11.4,17.42,3.91,8.27,7.22,19.04,7.7,32.12.16,5.9-.21,11.95-1.15,18.05-2.7,16.81-9.72,34.24-21.14,52.23-8.31,13.11-18.93,26.49-31.82,40.06,50.59,50.08,350.83,46.76,350.83,46.76,237.52-4.28,235.1-59.17,235.1-59.17l.08-153.58Z" />
			<path
				id="logo-knife-shape"
				class="bold"
				d="m2762.93,537.46c-.23-.79-27.03-78.07,1.39-162.28l.6-1.82,10.7-5.47-.57-1.09,4.37,1.68,4.53,1.78,4.29,1.69,15.23,5.9-1.25-5.44-.62-2.66-2.18-.85-15.81-6.17-3.82-1.49-3.05-1.23-2.93-1.16,3.5-6.25-1.82-10.56c-2.6-3.14-9.93-13.3-8.83-26.54.78-10.4,6.46-20.05,16.95-28.73q-.02-.08-.05-.16c-3.93-15.3-16.17-69.25.78-90.98,4.93-6.31,11.78-9.46,20.35-9.36,4.32-.85,30.57-4.96,45.32,9.28,8.4,8.19,11.25,20.49,8.56,36.22-.17,3.34-3.34,16.17-29.93,63.82,3.34,4.76,9.53,15.47,8.25,28.07-1.03,9.82-6.26,18.65-15.66,26.16l-4.28,9.64c-.41,2.47-1.46,7.23-3.46,10.82,2.86,3.31,7.32,9.12,11.12,17.41,3.8,8.28,7.03,19.04,7.5,32.13.16,5.89-.21,11.94-1.13,18.03-2.63,16.81-9.46,34.25-20.59,52.25-9.87,15.97-23.1,32.4-39.6,49.03l-5.33,5.42-2.54-7.13Z" />
			<path
				id="logo-knife-shape"
				class="std"
				d="M2776.93,537.46c-0.23-0.79-27.03-78.07,1.39-162.28l0.6-1.82l10.7-5.47l-0.57-1.09l4.37,1.68l4.53,1.78
		l4.29,1.69l15.23,5.9l-1.25-5.44l-0.62-2.66l-2.18-0.85l-15.81-6.17l-3.82-1.49l-3.05-1.23l-2.93-1.16l3.5-6.25l-1.82-10.56
		c-2.6-3.14-9.93-13.3-8.83-26.54c0.78-10.4,6.46-20.05,16.95-28.73c-0.02-0.08-0.02-0.08-0.04-0.16
		c-3.93-15.3-16.17-69.25,0.78-90.98c4.93-6.31,11.78-9.46,20.35-9.36c4.32-0.85,30.57-4.96,45.32,9.28
		c8.4,8.19,11.25,20.49,8.56,36.22c-0.17,3.34-3.34,16.17-29.93,63.82c3.34,4.76,9.53,15.47,8.25,28.07
		c-1.03,9.82-6.26,18.65-15.66,26.16l-4.28,9.64c-0.41,2.47-1.46,7.23-3.46,10.82c2.86,3.31,7.32,9.12,11.12,17.41
		c3.8,8.28,7.03,19.04,7.5,32.13c0.16,5.89-0.21,11.94-1.13,18.03c-2.64,16.81-9.46,34.25-20.59,52.25
		c-9.87,15.97-23.1,32.4-39.6,49.03l-5.33,5.42L2776.93,537.46z" />
		</svg>
	`,
	styles: `
		:host {
			display: block;
		}
		#logo_scb {
			width: 100%;
			height: 100%;
			--_logoorange: var(--logoorange-color, #d57e00);
			--_logored: var(--logored-color, #6a1a31);
			--_logobrown: var(--logobrown-color, #443736);
			--_stroke-small: 0px;
			--_stroke-big: 0px;
			.bistrot-text-bold {
				display: none;
			}
			.bistrot-text-std {
				display: block;
			}
		}

		.bold #logo_scb {
			--_stroke-small: 8px;
			--_stroke-big: 14px;

			.bistrot-text-bold {
				display: block;
			}
			.bistrot-text-std {
				display: none;
			}
		}
		.white #logo_scb {
			--_logoorange: white;
			--_logored: white;
			--_logobrown: white;
		}
		.black #logo_scb {
			--_logoorange: black;
			--_logored: black;
			--_logobrown: black;
		}
	`,
})
export class ScbLogoComponent implements AfterViewInit {
	uiSrv = inject(UiService);
	elRef = inject(ElementRef);
	destroyRef = inject(DestroyRef);

	autoBold = input(false);
	autoBoldThresholdWidth = input(230);

	autoBold$ = toObservable(this.autoBold);

	ngAfterViewInit(): void {
		this.autoBold$
			.pipe(
				switchMap((autoBold) => {
					if (autoBold) {
						return this.uiSrv.windowResize$.pipe(
							debounceTime(200),
							tap(() => {
								this.elRef.nativeElement.classList.toggle(
									'bold',
									this.elRef.nativeElement.childNodes[0].clientWidth < this.autoBoldThresholdWidth()
								);
							})
						);
					} else {
						return EMPTY;
					}
				}),
				takeUntilDestroyed(this.destroyRef)
			)
			.subscribe();
	}
}
