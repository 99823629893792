<mat-toolbar class="app-navbar no-print">
	<nav>
		@if (uiSrv.isLargeScreen()) {
			<a aria-label="Home" class="navbar-brand" routerLink="/">
				<app-scb-logo class="bold" [autoBold]="false"></app-scb-logo>
			</a>

			<a [class.active]="active() === 'offer'" routerLink="/menus" mat-button> Menù</a>
			<a [class.active]="active() === 'location'" [matMenuTriggerFor]="locationMenu" mat-button>
				<span style="display: flex">Il Bistrot <mat-icon style="display: inline-block">arrow_drop_down</mat-icon></span>
			</a>

			<mat-menu #locationMenu>
				<a routerLink="/location/explore" mat-menu-item href="#">Esplora 360°</a>
				<a routerLink="/location/where-are-we" mat-menu-item href="#">Dove siamo</a>
			</mat-menu>

			<a [class.active]="active() === 'events'" routerLink="/menus" mat-button> Eventi</a>
			<a [class.active]="active() === 'booking'" routerLink="/book/direct" mat-button> Prenota</a>

			@if (authSrv.userSgn(); as user) {
				<ng-container [ngTemplateOutlet]="userAvatar" [ngTemplateOutletContext]="{ $implicit: user }"></ng-container>
			} @else {
				<button appEventPreventDefault (click)="showLoginDialog()" mat-button>ACCEDI</button>
			}

			<button (click)="uiSrv.toggleTheme()" mat-icon-button>
				<mat-icon>{{ uiSrv.isDarkTheme() ? 'light_mode' : 'dark_mode' }}</mat-icon>
			</button>

			<app-language-switcher class="d-block"></app-language-switcher>
		} @else {
			<a aria-label="Home" class="navbar-brand" routerLink="/">
				<app-scb-logo class="bold" [autoBold]="false"></app-scb-logo>
			</a>
			@if (authSrv.userSgn(); as user) {
				<ng-container [ngTemplateOutletContext]="{ $implicit: user }" [ngTemplateOutlet]="userAvatar"></ng-container>
			} @else {
				<button appEventPreventDefault (click)="showLoginDialog()" mat-button>ACCEDI</button>
			}

			<app-language-switcher class="d-block"></app-language-switcher>

			<button class="d-block" (click)="uiSrv.toggleSidenav()" mat-icon-button yPosition="below" xPosition="left">
				<mat-icon>menu</mat-icon>
			</button>
		}
	</nav>
</mat-toolbar>

<mat-menu #navMenu="matMenu">
	<a [class.active]="active() === 'offer'" routerLink="/menus" mat-menu-item> Menù</a>
	<a [class.active]="active() === 'location'" [matMenuTriggerFor]="locationMenu" mat-menu-item> Il Bistrot</a>
	<a [class.active]="active() === 'events'" routerLink="/menus" mat-menu-item> Eventi</a>
	<a [class.active]="active() === 'booking'" routerLink="/book/direct" mat-menu-item> Prenota</a>
	<button (click)="uiSrv.toggleTheme()" mat-menu-item>
		<mat-icon>{{ uiSrv.isDarkTheme() ? 'light_mode' : 'dark_mode' }}</mat-icon>
		<span>Cambia tema</span>
	</button>
</mat-menu>

<mat-menu #locationMenu="matMenu">
	<a routerLink="/location/explore" mat-menu-item href="#">Esplora 360°</a>
	<a routerLink="/location/where-are-we" mat-menu-item href="#">Dove siamo</a>
</mat-menu>

<ng-template #userAvatar let-user>
	<button class="button-unstyled app-nav-user" [matMenuTriggerFor]="userMenu">
		<div class="app-user-avatar">
			<img
				alt="Foto contatto"
				[src]="user?.contact?.photoUrl"
				matListItemAvatar
				onerror="this.src='/assets/images/user_default_white.png'; this.onerror=null;" />
		</div>
	</button>
</ng-template>

<mat-menu #userMenu="matMenu">
	<a class="nav-link" routerLink="/user/profile" mat-menu-item href="#">Il mio profilo</a>
	<a class="nav-link" mat-menu-item routerLink="/user/coupons" href="#">I miei coupons</a>
	<button class="nav-link" (click)="authSrv.logOut()" mat-menu-item routerLink="/home" href="#">Esci</button>
</mat-menu>
