<div mat-dialog-title>
	<h1 style="display: flex; justify-content: space-between; align-items: center">
		Accedi <button aria-label="Chiudi" type="button" (click)="close()" mat-icon-button><mat-icon>close</mat-icon></button>
	</h1>
</div>
<mat-dialog-content mat-dialog-body>
	<p>Ti invieremo un link via email per accedere</p>
	<mat-form-field appearance="outline" style="display: block">
		<mat-label>Email</mat-label>
		<input name="email" id="email" type="text" [formControl]="emailCtrl" placeholder="Inserisci la tua Email" required matInput />
		@if (emailCtrl.touched && emailCtrl.errors?.required) {
			<mat-error>Compila questo campo</mat-error>
		}
		@if (emailCtrl.touched && emailCtrl.errors?.pattern) {
			<mat-error>Email non valida</mat-error>
		}
	</mat-form-field>
</mat-dialog-content>

<mat-dialog-actions style="justify-content: flex-end">
	<button aria-label="cancel" class="d-block" (click)="close()" mat-button>Annulla</button>

	<button aria-label="ok" class="d-block" (click)="sendLoginLink()" mat-flat-button>Invia Email</button>
</mat-dialog-actions>
