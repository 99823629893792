import { HttpParams } from '@angular/common/http';
import { Component, Inject } from '@angular/core';
import { ReactiveFormsModule, UntypedFormControl, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIcon } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { EMAIL_REGEXP } from '@scbweb-lib/utils';
import { AuthService } from 'app/_shared/services/auth.service';
import { TOAST_DURATIONS, UiService } from 'app/_shared/services/ui.service';
import { of } from 'rxjs';
import { catchError, exhaustMap, tap } from 'rxjs/operators';

export interface ILoginEmailDialogData {
	email?: string;
	redirectOnSuccessUrl?: string;
}

@Component({
	selector: 'app-login-email-dialog',
	templateUrl: './login-email-dialog.component.html',
	styleUrls: ['./login-email-dialog.component.scss'],
	standalone: true,
	imports: [ReactiveFormsModule, MatIcon, MatButtonModule, MatDialogModule, MatFormFieldModule, MatInputModule],
})
export class LoginEmailDialogComponent {
	emailCtrl: UntypedFormControl = new UntypedFormControl(
		this.data?.email ?? null,
		Validators.compose([Validators.required, Validators.pattern(EMAIL_REGEXP)])
	);

	constructor(
		public dialogRef: MatDialogRef<LoginEmailDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public data: ILoginEmailDialogData,
		private authSrv: AuthService,
		private uiSrv: UiService
	) {}

	sendLoginLink() {
		if (!this.emailCtrl.valid) {
			this.emailCtrl.markAllAsTouched();
			this.uiSrv.addToastMsgToQueue({ msg: "Compila l'email correttamente", isErrorMsg: true });
			return;
		}
		const params = this.data?.redirectOnSuccessUrl
			? new HttpParams().append('redirectOnSuccessUrl', this.data?.redirectOnSuccessUrl)
			: undefined;

		of(this.emailCtrl.value)
			.pipe(
				exhaustMap((email) => this.authSrv.sendLoginLinkToEmail(email, params)),
				tap(() => this.close()),
				tap(() =>
					this.uiSrv.addToastMsgToQueue({
						msg: `Ti abbiamo inviato una email all'indirizzo ${this.emailCtrl.value}. Clicca sul link per accedere.`,
						isSuccessMsg: true,
						duration: TOAST_DURATIONS.long,
					})
				),
				catchError((err) => {
					console.log(err);
					this.uiSrv.addToastMsgToQueue({
						msg: 'Non riuscito. Prova di nuovo.',
						isErrorMsg: true,
						duration: TOAST_DURATIONS.medium,
					});
					return of(null);
				})
			)
			.subscribe();
	}

	close() {
		this.dialogRef.close();
	}
}
