import { OverlayRef } from '@angular/cdk/overlay';
import { TemplateRef, Type } from '@angular/core';
import { Subject } from 'rxjs';
import { filter, take } from 'rxjs/operators';

export interface OverlayCloseEvent<R> {
	type: 'escape' | 'backdropClick' | 'close';
	data: R | null;
}

// R = Response Data Type, D = Data passed to Modal Type
export class MyOverlayRef<R, D> {
	afterClosed$ = new Subject<OverlayCloseEvent<R>>();

	constructor(
		public overlay: OverlayRef,
		public content: string | TemplateRef<unknown> | Type<unknown>,
		public data: D | undefined // pass data to modal i.e. FormData
	) {
		overlay
			.backdropClick()
			.pipe(
				// tap(v => console.log(v)),
				take(1)
			)
			.subscribe(() => this._close('backdropClick', null));

		overlay
			.keydownEvents()
			.pipe(
				// tap(v => console.log(v)),
				filter((event) => event.which === 27), // escape
				take(1)
			)
			.subscribe(() => this._close('escape', null));

		document.body.classList.add('modal-open');
	}

	close(data?: R) {
		this._close('close', data ?? null);
	}

	private _close(type: 'escape' | 'backdropClick' | 'close', data: R | null) {
		this.overlay.dispose();
		this.afterClosed$.next({ type, data });
		this.afterClosed$.complete();
		document.body.classList.remove('modal-open');
	}
}
