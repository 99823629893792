// This file can be replaced during build by using the `fileReplacements` array.// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.// The list of file replacements can be found in `angular.json`.import {
import { BUILD_TYPES, IFirebaseConfig, PAGE_META_DATA_DEFAULT, TEST_PAGE_META_DATA_OVERRIDES } from '@scbweb-lib/index';

export const environment = {
	production: true,
	buildType: BUILD_TYPES.test,
	version: '4.3.1',
	appName: 'Say CHEESE Bistrot',
	functionsDomain: 'https://us-central1-scb-website.cloudfunctions.net',
	indexPageMetaData: { ...PAGE_META_DATA_DEFAULT, ...TEST_PAGE_META_DATA_OVERRIDES },
	domain: 'https://testweb.saycheesebistrot.com',
};

export const SCBWEB_FIREBASE_CONFIG: IFirebaseConfig = {
	databaseURL: 'https://scb-website.firebaseio.com',
	apiKey: 'AIzaSyBq8jx2r49UAQntPj7en0nRtxa5oEw6XkY',
	authDomain: 'scb-website.firebaseapp.com',
	projectId: 'scb-website',
	storageBucket: 'scb-website.appspot.com',
	messagingSenderId: '658730155911',
	appId: '1:658730155911:web:10cbb7aa60e9804585dd22',
	measurementId: 'G-E3H9RT484D',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
