import { ChangeDetectionStrategy, Component } from '@angular/core';
import { PRIVACY_POLICY_VERSION } from '@scb-lib/index';
import { MyOverlayRef } from 'app/_shared/overlay-module/OverlayRef.class';

@Component({
	selector: 'app-privacy-policy',
	templateUrl: './privacy-policy.component.html',
	styleUrls: ['./privacy-policy.component.scss'],
	standalone: true,
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PrivacyPolicyComponent {
	PRIVACY_POLICY_VERSION = PRIVACY_POLICY_VERSION;
	constructor(private ref: MyOverlayRef<undefined, null>) {}

	close() {
		this.ref.close();
	}
}
