import { Directive, ElementRef, Input, OnDestroy } from '@angular/core';

@Directive({
	selector: '[appAnimateOnVisible]',
	standalone: true,
})
export class AnimateOnVisibleDirective implements OnDestroy {
	private elVisibility: string | undefined;
	private elOpacity: string | undefined;
	private observer: IntersectionObserver | undefined;
	private ratio = 0.4;

	_enabled = true;
	@Input()
	set appAnimateOnVisible(val: '' | 'disabled') {
		this._enabled = val !== 'disabled';
		// console.log('input', this.el.nativeElement, this.elOpacity, this.elVisibility);
	}

	constructor(private el: ElementRef<HTMLElement>) {
		// console.log('constructor', this.el.nativeElement, this.elOpacity, this.elVisibility);
		// afterNextRender(() => {
		// 	this.elOpacity = this.el.nativeElement.style.opacity;
		// 	this.elVisibility = this.el.nativeElement.style.visibility;
		// 	console.log('afterNextRender', this.el.nativeElement);
		// 	if (this._enabled && !this.el.nativeElement.classList.contains('animate__animated')) {
		// 		this.el.nativeElement.style.opacity = '0';
		// 		this.el.nativeElement.style.visibility = 'hidden';
		// 	} else {
		// 		this.el.nativeElement.style.opacity = this.elOpacity;
		// 		this.el.nativeElement.style.visibility = this.elVisibility;
		// 	}
		// 	this.observer = new IntersectionObserver(this.handleIntersect.bind(this), {
		// 		root: null,
		// 		rootMargin: '10% 0%',
		// 		threshold: [0, this.ratio],
		// 	});
		// 	this.observer.observe(this.el.nativeElement);
		// });
	}

	handleIntersect(entries: IntersectionObserverEntry[], observer: IntersectionObserver) {
		console.log('handle intersect', this.el.nativeElement);
		if (!this._enabled) {
			return;
		}

		entries.forEach((entry) => {
			// console.log(entry);

			if (
				entry.isIntersecting &&
				(entry.intersectionRatio > this.ratio || entry.boundingClientRect.height > (entry.rootBounds?.height ?? 0))
			) {
				// if element is bigger than root, intersectionRatio may never be met

				const scrollingDown =
					entry.boundingClientRect.bottom > (entry.rootBounds?.bottom ?? 0)
						? true
						: entry.boundingClientRect.top < (entry.rootBounds?.top ?? 0)
							? false
							: true;

				if (scrollingDown) {
					this.el.nativeElement.style.opacity = this.elOpacity || '1';
					this.el.nativeElement.style.visibility = this.elVisibility || 'visible';
					this.el.nativeElement.classList.add('animate__animated');
					this.el.nativeElement.classList.add('animate__slideInUp');
					this.el.nativeElement.classList.add('animate__faster');
				} else {
					this.el.nativeElement.style.opacity = this.elOpacity || '1';
					this.el.nativeElement.style.visibility = this.elVisibility || 'visible';
					this.el.nativeElement.classList.add('animate__animated');
					this.el.nativeElement.classList.add('animate__fadeInDown');
					this.el.nativeElement.classList.add('animate__faster');
				}
				observer.unobserve(this.el.nativeElement);
				observer.disconnect();
			}
		});
	}

	ngOnDestroy(): void {
		this.observer?.unobserve(this.el.nativeElement);
		this.observer?.disconnect();
	}
}
