export const CRM_API_ADDRESS = 'crmApi';export enum CRM_API_ENDPOINTS {
    checkContactExistByEmail = 'checkContactExistByEmail',
    checkContactExistByMobile = 'checkContactExistByMobile',
    getContactByEmail = 'getContactByEmail',
    addNewContact = 'addNewContact',
}

export const BOOKING_API_ADDRESS = 'bookingApi';

export enum BOOKING_API_ENDPOINTS {
    addBooking = 'addBooking',
}

export const COUPON_API_ADDRESS = 'couponApi';
export enum COUPON_API_ENDPOINTS {
    couponPersonalRequest = 'couponPersonalRequest',
}
