import { ChangeDetectionStrategy, Component, OnDestroy, input, viewChild } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatMenuModule } from '@angular/material/menu';
import { MatDialog } from '@angular/material/dialog';
import { Router, RouterLink, RouterLinkActive } from '@angular/router';
import { ILoginEmailDialogData, LoginEmailDialogComponent } from 'app/auth/cmp/login-email-dialog/login-email-dialog.component';
import { AuthService, IUser } from 'app/_shared/services/auth.service';
import { UiService } from 'app/_shared/services/ui.service';
import { Observable, Subject } from 'rxjs';
import { LanguageSwitcherComponent } from '../language-switch/language-switcher.component';
import { ScbLogoComponent } from '../scb-logo/scb-logo.component';
import { CommonModule, NgTemplateOutlet } from '@angular/common';

@Component({
	selector: 'app-nav-bar',
	templateUrl: './nav-bar.component.html',
	styleUrl: './nav-bar.component.scss',
	standalone: true,
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [
		RouterLink,
		RouterLinkActive,
		ScbLogoComponent,
		MatMenuModule,
		LanguageSwitcherComponent,
		MatToolbarModule,
		MatButtonModule,
		MatIcon,
		NgTemplateOutlet,
		CommonModule,
	],
})
export class NavBarComponent implements OnDestroy {
	scblogo = viewChild(ScbLogoComponent);

	active = input<'home' | 'location' | 'offer' | 'updates' | 'events' | 'contacts' | 'order' | 'booking' | undefined>();

	onDestroy$: Subject<void> = new Subject();
	user$: Observable<IUser | null | undefined> = this.authSrv.user$;

	constructor(
		public uiSrv: UiService,
		public authSrv: AuthService,
		private dialog: MatDialog,
		private router: Router
	) {}

	ngOnDestroy(): void {
		this.onDestroy$.next();
		this.onDestroy$.complete();
	}

	showLoginDialog() {
		const urlTree = this.router.parseUrl(this.router.url);
		urlTree.queryParams = {};
		const data = { redirectOnSuccessUrl: urlTree.toString() };
		this.dialog.open<void, ILoginEmailDialogData>(LoginEmailDialogComponent, { data, panelClass: 'dialog-max-width' });
	}
}
