import { AfterViewInit, Component, TemplateRef, Type, inject } from '@angular/core';
import { MyOverlayRef } from '../OverlayRef.class';
import { NgTemplateOutlet, NgComponentOutlet } from '@angular/common';

@Component({
	selector: 'app-overlay',
	templateUrl: './overlay.component.html',
	styleUrls: ['./overlay.component.scss'],
	standalone: true,
	imports: [NgTemplateOutlet, NgComponentOutlet],
})
export class OverlayComponent implements AfterViewInit {
	ref = inject(MyOverlayRef<unknown, unknown>);

	contentType: 'template' | 'string' | 'component';
	content: string | TemplateRef<unknown> | Type<unknown>;
	context: (unknown & { close: () => void }) | undefined;

	constructor() {
		this.content = this.ref.content;

		if (typeof this.content === 'string') {
			this.contentType = 'string';
		} else if (this.content instanceof TemplateRef) {
			this.contentType = 'template';
			this.context = {
				close: this.ref.close.bind(this.ref),
			};
		} else {
			this.contentType = 'component';
		}
		// console.log('this.contentType', this.contentType);
	}

	ngAfterViewInit(): void {
		// console.log('this.contentType', this.contentType);
		document.body.classList.add('modal-open');
	}

	close() {
		this.ref.close(null);
	}
}
